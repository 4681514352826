import React, { useEffect, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import tw from "twin.macro"
import playIcon from "@images/icons/icon-play-video.svg"
import nextVideoIcon from "@images/icons/arrow-purple.svg"
import mobilePlay from "@images/icons/arrow-mobile-play.svg"
import InlineVideo from "./inlineVideo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const VideoGallery = ({ videoData, activeVideo = 0 }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(activeVideo)
  const [galleryHidden, setGalleryHidden] = useState(false)
  const currentVideoRef = useRef()
  const [autoplay, setAutoplay] = useState(undefined)

  useEffect(() => {
    setActiveVideoIndex(activeVideo)
  }, [activeVideo])

  return (
    <div tw="text-[#3D3935]">
      {/* Active Video Content */}
      <div tw="relative z-10 md:(flex items-center justify-center column-gap[30px]) xl:(column-gap[50px] mb-[50px])">
        {/* Video Thumbnail */}
        <div css={[tw`relative mb-4 md:(mb-0 flex-1)`]} ref={currentVideoRef}>
          <InlineVideo
            wistiaId={videoData[activeVideoIndex].videoId}
            thumbnail={videoData[activeVideoIndex].videoThumbnail}
            eyebrowText=""
            singleCol
            autoplayId={autoplay}
            videoName={ReactDOMServer.renderToString(
              videoData[activeVideoIndex].header
            ).replace(/&#x27;/g, "'")}
          />
        </div>

        {/* Text */}
        <div css={[tw`md:(flex-1) xl:(max-w-[480px] px-14)`]}>
          <h3 tw="text-2xl font-medium mb-[15px] xl:(text-4xl mb-[25px])">
            {videoData[activeVideoIndex].header}
          </h3>
          <div tw="w-[70px] h-[5px] mb-[15px] xl:mb-[25px] background[linear-gradient(90deg, #F93549 0.36%, #AD95DC 100%)]" />
          <div tw="mb-[15px] xl:mb-[25px]">
            <p tw="text-base">{videoData[activeVideoIndex].copy}</p>
            {videoData[activeVideoIndex].footnote && (
              <p tw="mt-2 text-[12px]">
                {videoData[activeVideoIndex].footnote}
              </p>
            )}
          </div>
          {activeVideoIndex < videoData.length - 1 && (
            <button
              tw="flex items-center justify-center"
              onClick={() => {
                const newVideoIndex =
                  activeVideoIndex + 1 >= videoData.length
                    ? 0
                    : activeVideoIndex + 1
                setActiveVideoIndex(newVideoIndex)
                setAutoplay(videoData[newVideoIndex].videoId)
                if (currentVideoRef.current) {
                  currentVideoRef.current.scrollIntoView()
                }
              }}
            >
              <div tw="text-base uppercase font-bold mr-2.5 background[linear-gradient(270deg, #AD95DC 0%, #F93549 100%)] -webkit-background-clip[text] -webkit-text-fill-color[transparent]">
                Next Video
              </div>
              <img src={nextVideoIcon} alt="" />
            </button>
          )}
        </div>
      </div>

      <div tw="my-[15px] flex justify-end xl:hidden">
        <button
          className="video-gallery-toggle"
          tw="uppercase text-xl font-bold underline"
          onClick={() => {
            setGalleryHidden(!galleryHidden)
          }}
        >
          {galleryHidden ? "Show All Videos" : "Show Less"}
        </button>
      </div>

      {/* Video Gallery */}
      <div
        css={[
          tw`xl:(grid grid-cols-4 gap-5)`,
          galleryHidden && tw`hidden xl:grid`,
        ]}
      >
        {videoData.map((video, index) => (
          <button
            key={`video-gallery-item-${index}`}
            css={[
              tw`flex items-center justify-start w-full text-left py-[15px] border-t border-[#C4C4C4] xl:(inline-block py-0 border-none)`,
              index === videoData.length - 1 && tw`border-b xl:border-none`,
            ]}
            onClick={() => {
              setActiveVideoIndex(index)
              setAutoplay(videoData[index].videoId)
              if (currentVideoRef.current) {
                currentVideoRef.current.scrollIntoView()
              }
            }}
            className={`gallery-button-${index}`}
          >
            <div tw="relative min-w-[100px] xl:(mb-2.5 min-width[unset])">
              <GatsbyImage
                image={getImage(video.videoThumbnail)}
                alt={video.altText}
                tw="w-[100px] h-[56px] object-fit[cover] xl:(w-full h-auto)"
              />
              <img
                src={playIcon}
                tw="hidden xl:(absolute block w-[44px] h-[44px] left-[calc(50% - 22px)] top-[calc(50% - 22px)])"
                alt=""
              />
            </div>
            <img src={mobilePlay} alt="" tw="w-[30px] mx-[15px] xl:hidden" />
            <div
              css={[
                tw`flex-shrink xl:mb-2.5`,
                index === activeVideoIndex && tw`font-bold`,
              ]}
            >
              <div
                css={[
                  tw`xl:mb-[5px]`,
                  index === activeVideoIndex && tw`mb-[5px]`,
                ]}
              >
                {video.header}
              </div>
              <div
                css={[
                  tw`w-full h-[5px] background[linear-gradient(90deg, #F93549 0.36%, #AD95DC 100%)] xl:mb-0`,
                  index !== activeVideoIndex && tw`invisible mt-[-5px] xl:mt-0`,
                ]}
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}

export default VideoGallery
