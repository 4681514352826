import React, { useEffect, useState } from "react"
import tw from "twin.macro"

import { Tab } from "@headlessui/react"
import Section from "@components/section"
import Container from "@components/container"
import Anchor from "@components/anchor"
import { purpleButton } from "@styles/ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import blockquote from "@images/icons/blockquote.svg"
import checklist from "@images/icons/checklist.svg"

const TabbedProfiles = ({ profiles }) => {
  const [activeProfileIndex, setActiveProfileIndex] = useState(0)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const activeProfileIndex = searchParams.get("activeProfileIndex")

    if (activeProfileIndex) setActiveProfileIndex(parseInt(activeProfileIndex))
  }, [])

  useEffect(() => {
    const hash = window.location.hash
    if (hash === "#tabbed-profiles") {
      const element = document.getElementById("tabbed-profiles")
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  return (
    <Tab.Group
      selectedIndex={activeProfileIndex}
      onChange={setActiveProfileIndex}
      as="div"
      id="tabbed-profiles"
    >
      <Tab.List>
        <Section
          type="primary"
          bgColour="gradient9"
          addedStyles={tw`pb-0 xl:pb-0`}
        >
          <div id="here" tw="flex items-center justify-center">
            <div tw="flex rounded-full p-2 redToPurpleGradient xl:gap-[58px]">
              {profiles.map((profile, index) => (
                <Tab key={`profile-tab-${index}`}>
                  {({ selected }) => (
                    <div
                      css={[
                        tw`flex items-center rounded-full text-white py-2 px-4 xl:w-[250px]`,
                        selected && tw`bg-white text-merlin`,
                      ]}
                    >
                      <GatsbyImage
                        image={getImage(profile.headshotIcon)}
                        alt=""
                        tw="hidden xl:(block w-[50px] mr-5)"
                      />
                      <div tw="text-center xl:text-left">
                        <div tw="font-semibold uppercase">{profile.name}</div>
                        <div tw="text-[12px] font-semibold xl:(text-base font-normal)">
                          {profile.diagnosis}
                        </div>
                      </div>
                    </div>
                  )}
                </Tab>
              ))}
            </div>
          </div>
        </Section>
      </Tab.List>

      <Tab.Panels>
        {profiles.map((profile, index) => (
          <Tab.Panel key={`profile-content-${index}`} tabIndex={-1}>
            {/* Bio */}
            <Section type="primary" bgColour="gradient9">
              <Container grid>
                <div tw="col-span-full flex items-center mb-[30px] xl:(col-span-5 mb-0)">
                  <div tw="mb-[30px]">
                    <GatsbyImage
                      image={getImage(profile.image)}
                      alt=""
                      tw="w-[200px] ml-[-20px] xl:(w-full ml-0)"
                    />
                    <div tw="text-merlin text-[12px] mt-[11px] xl:text-base">
                      Actor Portrayal
                    </div>
                  </div>
                  <div tw="ml-[15px]">
                    <h2 tw="text-[40px] mb-[15px] xl:hidden">
                      Meet <span tw="font-bold">{profile.name}</span>
                    </h2>
                    <div tw="w-[58px] h-[5px] background[linear-gradient(40deg, #AD95DC 0%, #F93549 150%)] xl:(hidden)" />
                  </div>
                </div>
                <div tw="col-span-full xl:(col-start-7 col-span-5)">
                  <h2 tw="hidden xl:(block mb-[25px] text-[40px])">
                    Meet <span tw="font-bold">{profile.name}</span>
                  </h2>
                  <div tw="hidden xl:(block w-[58px] h-[5px] background[linear-gradient(40deg, #AD95DC 0%, #F93549 150%)] mb-[25px])" />
                  <p tw="mb-[19px] xl:mb-[50px]">{profile.bio.copy}</p>
                  <ul tw="list-style[disc] list-outside pl-[40px] xl:pl-[36px]">
                    <li tw="mb-2.5">
                      <strong>Age:</strong> {profile.bio.age}
                    </li>
                    <li tw="mb-2.5">
                      <strong>Sex:</strong> {profile.bio.sex}
                    </li>
                    <li tw="mb-2.5">
                      <strong>BP:</strong> {profile.bio.bp}
                    </li>
                    <li tw="mb-2.5">
                      <strong>Total cholesterol:</strong>{" "}
                      {profile.bio.cholesterol}
                    </li>
                    <li tw="mb-2.5">
                      <strong>Family health history:</strong>{" "}
                      {profile.bio.healthHistory}
                    </li>
                    <li>
                      <strong>Lifestyle:</strong> {profile.bio.lifestyle}
                    </li>
                  </ul>
                </div>
              </Container>
            </Section>

            {/* Blockquote */}
            <Section
              type="primary"
              bgColour="gradient9"
              addedStyles={tw`py-[75px]`}
            >
              <Container>
                <div tw="relative col-span-full">
                  <div tw="text-2xl text-center px-[26px] xl:(px-[100px] py-5 text-4xl)">
                    {profile.blockQuote}
                  </div>
                  <img
                    src={blockquote}
                    alt=""
                    tw="w-[137px] absolute left-0 top-[-70px] xl:top-[-30px] z-[-1]"
                  />
                  <img
                    src={blockquote}
                    alt=""
                    tw="w-[137px] absolute right-0 bottom-[-65px] xl:bottom-[-30px] z-[-1] rotate-180"
                  />
                </div>
              </Container>
            </Section>

            {/* Clinical History */}
            <Section type="primary" bgColour="gradient9">
              <Container>
                <div tw="col-span-full flex flex-col bg-white rounded-tl-[130px] rounded-br-[130px] pt-[50px] pb-[68px] px-[31px] xl:(flex-row py-[53px] px-[100px])">
                  <div tw="w-full flex flex-col items-center justify-center xl:(w-[45%] flex-row justify-start)">
                    <img src={checklist} alt="" tw="w-[165px] xl:mr-[34px]" />
                    <div>
                      <h2 tw="text-4xl font-semibold mb-[15px] xl:(text-[40px] mb-[25px])">
                        Clinical
                        <br tw="hidden xl:(block)" /> History
                      </h2>
                      <div tw="w-[70px] h-[5px] background[linear-gradient(40deg, #AD95DC 0%, #F93549 150%)] mx-auto mb-[15px] xl:(mx-[unset] mb-0)" />
                    </div>
                  </div>
                  <div tw="w-full xl:w-[55%]">
                    <ul tw="list-style[disc] xl:column-count[2]">
                      {profile.clinicalHistory.map((history, cIndex) => (
                        <li
                          key={`${profile.name}-clinical-history-${cIndex}`}
                          tw="mb-2.5 xl:pr-4"
                        >
                          {history}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Container>
            </Section>

            {/* List Content */}
            <Section type="primary" bgColour="gradient9">
              <Container>
                <div tw="col-span-full xl:(col-start-3 col-span-8 px-[120px])">
                  {profile.listContent.map((listContent, listIndex) => (
                    <div
                      key={`${profile.name}-list-${listIndex}`}
                      tw="mb-10 xl:(mb-[70px] last-of-type:mb-0)"
                    >
                      <h2 tw="text-center font-semibold text-2xl mb-[15px] xl:(text-4xl mb-[25px])">
                        {listContent.header}
                      </h2>

                      <div tw="w-[70px] h-[5px] background[linear-gradient(40deg, #AD95DC 0%, #F93549 150%)] mx-auto mb-[15px] xl:mb-[50px]" />
                      {listContent.subHeader?.eyebrow && (
                        <div tw="text-center font-semibold uppercase text-[12px] mb-[15px] xl:(text-base)">
                          {listContent.subHeader.eyebrow}
                        </div>
                      )}
                      <div tw="xl:px-[100px]">
                        <h3 tw="text-center text-2xl mb-[30px] xl:(text-4xl mb-[50px])">
                          {listContent.subHeader?.copy}
                        </h3>
                        <ul tw="list-style[disc] pl-3.5">
                          {listContent.list.map((item, innerListIndex) => (
                            <li
                              key={`${profile.name}-list-item-${innerListIndex}`}
                            >
                              <p tw="mb-2.5">{item.copy}</p>
                              {item.subList && (
                                <ul tw="list-style[disc] px-8 xl:(pl-5 pr-0)">
                                  {item.subList.map(
                                    (subListItem, subListItemIndex) => (
                                      <li
                                        key={`${profile.name}-list-item-${innerListIndex}-sublist-item-${subListItemIndex}`}
                                        tw="mb-2.5"
                                      >
                                        {subListItem.copy}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                        <div tw="text-[12px] mt-[25px] mb-[15px] text-center xl:mb-[25px]">
                          {listContent.footnote}
                        </div>
                      </div>

                      {listContent.references && (
                        <>
                          <hr tw="my-[25px]" />
                          <ul tw="text-[12px] max-w-[570px] mx-auto">
                            {listContent.references.map(
                              (reference, referenceindex) => (
                                <li
                                  key={`${profile.name}-list-${listIndex}-reference-${referenceindex}`}
                                  tw="flex"
                                >
                                  {reference}
                                </li>
                              )
                            )}
                          </ul>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </Container>
            </Section>

            {/* Cards */}
            <Section
              type="primary"
              bgColour="gradient9"
              addedStyles={tw`py-0 xl:py-0`}
            >
              <Container>
                <div tw="flex flex-col justify-center xl:flex-row">
                  {profile.cards.map((card, cardIndex) => (
                    <div
                      key={`${profile.name}-card-${cardIndex}`}
                      css={[
                        tw`flex flex-col justify-between my-[23px] pt-[33px] pb-[72px] px-[18px] text-center bg-white rounded-tl-[100px] rounded-br-[100px] xl:(py-[74px] px-[60px] mx-[15px] my-0 rounded-tl-[130px] rounded-br-[130px])`,
                        profile.cards.length > 1
                          ? tw`xl:max-w-[470px]`
                          : tw`xl:max-w-[774px]`,
                      ]}
                    >
                      <div>
                        <img
                          src={card.image}
                          alt=""
                          tw="w-[95px] mb-[25px] mx-auto"
                        />
                        <p tw="font-bold">{card.copy}</p>
                        <p tw="mt-[7px] text-[12px] xl:px-2">{card.subCopy}</p>
                      </div>
                      <div>
                        <Anchor
                          link={card.cta.link}
                          addedStyles={[
                            purpleButton,
                            tw`mt-[30px] inline-block px-4`,
                          ]}
                        >
                          {card.cta.text}
                        </Anchor>
                      </div>
                    </div>
                  ))}
                </div>
              </Container>
            </Section>

            {/* CTA */}
            <Section type="primary" bgColour="gradient9">
              <Container>
                <div tw="xl:px-[100px] text-center">
                  <GatsbyImage
                    image={getImage(profile.cta.image)}
                    alt=""
                    tw="mx-auto w-[150px] mb-[25px] xl:mb-[50px]"
                  />
                  <h2 tw="text-2xl mb-[25px] font-semibold xl:(text-4xl)">
                    {profile.cta.header}
                  </h2>
                  <div tw="w-[70px] h-[5px] background[linear-gradient(40deg, #AD95DC 0%, #F93549 150%)] mb-[50px] mx-auto" />
                  <a
                    href="#tabbed-profiles"
                    css={[purpleButton, tw`inline-block px-4`]}
                    onClick={() => {
                      setActiveProfileIndex(profile.cta.profileIndex)
                    }}
                  >
                    {profile.cta.text}
                  </a>
                </div>
              </Container>
            </Section>

            {/* References */}
            <Section type="primary" bgColour="gradient9">
              <Container>
                <div tw="px-[20px] text-[12px] col-span-full xl:px-[100px]">
                  <ul tw="list-style[decimal]">
                    {profile.references.map((reference, refIndex) => (
                      <li key={`${profile.name}-references-${refIndex}`}>
                        {reference}
                      </li>
                    ))}
                  </ul>
                </div>
              </Container>
            </Section>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default TabbedProfiles
