import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import Anchor from "@components/anchor"

const ResourceCard = ({ narcolepsy, ih, cardData }) => {
  return (
    <div tw="flex flex-col md:flex-row">
      {cardData &&
        cardData.map((card, i) => {
          return (
            <div
              key={i}
              css={[
                tw`flex w-full rounded-[40px] first-of-type:mb-5 md:first-of-type:(mb-0 mr-[30px])`,
                narcolepsy && tw`gradient33`,
                ih && tw`gradient34`,
              ]}
              id={card?.anchor}
            >
              <div tw="flex flex-col justify-between leading-[23.4px]">
                <div tw="mr-[15px] xl:(mr-[45px])">
                  <h3
                    id={card.hashId}
                    tw="uppercase font-semibold mt-10 mb-5 ml-10 xl:(leading-[31.2px])"
                  >
                    {card.title}
                  </h3>
                  <p tw="font-normal mb-[30px] ml-10 xl:(mr-8 mb-[15px])">
                    {card.description}
                  </p>
                </div>
                <p tw="mb-10 ml-10 font-normal leading-[20.8px]">
                  PDF<span tw="mx-4">| </span>
                  {card.size}
                </p>
              </div>
              <div
                css={[
                  tw`min-w-[70px] xl:min-w-[100px] flex justify-center items-center border-l `,
                  narcolepsy && tw`border-alto`,
                  ih && tw`border-white`,
                ]}
              >
                <Anchor
                  link={card.pdf}
                  newTab
                  addedStyles={tw`w-full h-full flex justify-center transition-main items-center xl:(hover:([&>svg>path]:stroke-[#000000]))`}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 23 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 10L11.7991 21L1.5 10"
                      stroke="#EB1429"
                      strokeWidth="3"
                    ></path>
                    <path
                      d="M11.5 21V0"
                      stroke="#EB1429"
                      strokeWidth="3"
                    ></path>
                    <path
                      d="M1 29L22 29"
                      stroke="#EB1429"
                      strokeWidth="3"
                    ></path>
                  </svg>
                </Anchor>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default ResourceCard

ResourceCard.propTypes = {
  narcolepsy: PropTypes.bool,
  ih: PropTypes.bool,
  cardData: PropTypes.array.isRequired,
}

ResourceCard.defaulProps = {
  narcolepsy: false,
  ih: false,
  cardData: [],
}
